import { AxiosInstance } from 'axios';

import {
  Badge,
  GetPendingNotificationsResponse,
  PointsTrigger,
} from '../definitions/rewards.definitions';

/**
 * Get pending rewards for the authenticated user.
 * @param api An Axios instance
 * @returns Pending notifications
 */
export const getPendingNotifications = (api: AxiosInstance) => {
  return api.get<GetPendingNotificationsResponse>('/rewards/pending-notifications', {
    authEnabled: true,
  });
};

/**
 * Get a list of points triggers.
 * @param api An Axios instance
 * @returns Points triggers
 */
export const getPointsTriggers = (api: AxiosInstance) => {
  return api.get<PointsTrigger[]>('/rewards/points');
};

/**
 * Get Badges Overview
 * @param api An Axios instance
 * @returns Points triggers
 */
export const getBadges = (api: AxiosInstance, userId: string) => {
  return api.get<Badge[]>(`/rewards/badges/${userId}`, { authEnabled: true });
};
