import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { getAuth, logout, setAuthTokens } from 'hooks/auth/util';
import TagManager from 'react-gtm-module';

import { Events } from 'constants/events';
import apiService from 'services/api/ApiService';

import { Headers } from '../../headers';

type OpenRetryConnection = {
  resolve: (value: unknown) => void;
  reject: (reason?: unknown) => void;
  originalRequest: AxiosRequestConfig;
};

let openRetryConnections: OpenRetryConnection[] = [];
let isRefreshingToken = false;

const success = (response: AxiosResponse) => response;

const error = (error: AxiosError) => {
  // @ts-ignore
  if (shouldInterceptError(error) && !error.response?.config?.headers[Headers.RetryHeader]) {
    const { refreshToken } = getAuth();
    if (refreshToken) {
      return retryWithNewAccessToken(refreshToken, error.config);
    } else {
      return handleRefreshFailure();
    }
  }

  return Promise.reject(error);
};

const retryWithNewAccessToken = (refreshToken: string, originalRequest: AxiosRequestConfig) =>
  new Promise((resolve, reject) => {
    openRetryConnections.push({
      resolve,
      reject,
      originalRequest,
    });

    if (!isRefreshingToken) {
      isRefreshingToken = true;
      refreshAndResolveOpenCalls(refreshToken);
    } else if (originalRequest.url?.includes('refreshtoken')) {
      console.info('Refresh token failed - user needs to login again');
      // Refresh failed, user needs to login again
      handleRefreshFailure();
    }
  });

const refreshAndResolveOpenCalls = async (refreshToken: string) => {
  console.info('Auth - refreshing token');
  const { user } = getAuth();

  try {
    const { accessToken } = await apiService.refreshDigicelIDAccessToken(refreshToken);

    if (!accessToken) {
      handleRefreshFailure();
      return;
    }

    setAuthTokens(accessToken, refreshToken);

    TagManager.dataLayer({
      dataLayer: {
        event: Events.login,
        user: { id: user?.id },
      },
    });

    resolveOpenConnectons(accessToken);
  } catch (error) {
    handleRefreshFailure();
  }
};

const handleRefreshFailure = () => {
  logout();
  rejectOpenConnections();
};

const rejectOpenConnections = () => {
  openRetryConnections.forEach(({ reject }) => {
    reject();
  });

  openRetryConnections = [];

  isRefreshingToken = false;
};

const resolveOpenConnectons = (accessToken: string) => {
  openRetryConnections.forEach(({ resolve, originalRequest }) => {
    if (originalRequest.headers) originalRequest.headers.Authorization = `Bearer ${accessToken}`;
    return resolve(axios(originalRequest));
  });

  openRetryConnections = [];

  isRefreshingToken = false;
};

const INTERCEPT_STATUS_CODES = [401];

const shouldInterceptError = (error: AxiosError) =>
  error.response && INTERCEPT_STATUS_CODES.includes(error.response.status);

export default { success, error };
