// We use this because of rehydration https://www.joshwcomeau.com/react/the-perils-of-rehydration/
// Use this component wisely. Only when you need it.

import { useEffect } from 'react';

import { atom, useRecoilState } from 'recoil';

export const mountedState = atom({
  key: 'mounted',
  default: false,
});

export default function useMounted() {
  const [mounted, setMounted] = useRecoilState(mountedState);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mounted;
}
