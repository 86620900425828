export enum Routes {
  home = '/',
  collectionBottomNavigation = '/collection/[slug]',
  collection = '/collection/:slug',
  discover = '/discover',
  favorites = '/favorites',
  friends = '/friends',
  friendDetails = '/friends/:username',
  addFriends = '/add-friends',
  gameDetails = '/games/:slug',
  game = '/game/:slug',
  login = '/login',
  onboarding = '/onboarding',
  verificationCode = '/login/verification-code',
  language = '/language',
  faq = '/faq',
  pwa = '/pwa-install',
  contact = '/contact',
  notFound = '/404',
  unsupportedRegion = '/error/unsupported-region',
  unvalidatedPhoneNumber = '/error/unvalidated-phone-number',
  profile = '/profile',
  editProfile = '/profile/edit',
  points = '/points',
  badges = '/badges/:username',
  rewards = '/rewards',
  settings = '/settings',
  subscriptions = '/subscriptions',
  credit = '/credit',
  creditCallback = '/payment/callback',
  interests = '/interests',
  privacySettings = '/privacy-settings',
  terms = '/terms-and-conditions',
  privacyPolicy = '/privacy-policy',
  cookiePolicy = '/cookie-policy',
  maintenance = '/500',
  appearance = '/appearance',
}
