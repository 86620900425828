import { AxiosInstance } from 'axios';

import { SearchResponse } from '../definitions/search.definitions';

/**
 * Search for games and collections.
 * @param api An Axios instance + term
 * @returns games and collections
 */
export const search = (api: AxiosInstance, term: string, collectionId?: string) =>
  api.get<SearchResponse>('/search', {
    params: {
      term,
      collectionId,
    },
  });
