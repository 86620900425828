import React, { useRef } from 'react';

import BadgeNotificationManager, { BadgeNotificationContext } from './BadgeNotificationManager';
import { useHandleNotifications } from './useHandleNotifications';

const NotificationHandler: React.FC = ({ children }) => {
  useHandleNotifications();

  return <>{children}</>;
};

const NotificationsProvider: React.FC = ({ children }) => {
  const managerRef = useRef<BadgeNotificationManager>(null);

  return (
    <>
      <BadgeNotificationManager ref={managerRef} />
      <BadgeNotificationContext.Provider value={managerRef}>
        <NotificationHandler>{children}</NotificationHandler>
      </BadgeNotificationContext.Provider>
    </>
  );
};

export default NotificationsProvider;
