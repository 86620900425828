import { AxiosInstance } from 'axios';
import getConfig from 'next/config';

import { AuthenticationTokens } from 'services/api/definitions/authentication.definitions';

const { publicRuntimeConfig: config } = getConfig();

/**
 * This endpoint is used to exchange the DigicelID's authorizationToken for an Access Token.
 * @param api An Axios instance
 * @returns access and refresh token
 */
export const getDigicelIDAccessToken = (api: AxiosInstance, authorizationToken: string) =>
  api.post<AuthenticationTokens>(`/auth/token`, {
    authorizationToken,
    redirectUri: `${config.CLIENT_URL}/auth/callback`,
  });

/**
 * This endpoint is used to refresh the DigicelID's access token using a refresh token.
 * @param api An Axios instance
 * @returns new accessToken
 */
export const refreshDigicelIDAccessToken = (api: AxiosInstance, refreshToken: string) =>
  api.post<{ accessToken: string }>(`/auth/refreshtoken`, {
    refreshToken,
  });
