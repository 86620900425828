import React from 'react';

import { makeStyles } from '@material-ui/core';
import { SnackbarProvider as NotistackProvider } from 'notistack';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 9999,
    bottom: 60,
  },
  bottomCenter: {
    bottom: 54,
    maxWidth: '100%',
  },
  info: { backgroundColor: 'rgba(2, 2, 53, 1)' },
}));

const SnackbarProvider: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <NotistackProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={3000}
      classes={{
        containerRoot: classes.root,
        containerAnchorOriginBottomCenter: classes.bottomCenter,
        variantInfo: classes.info,
      }}
      preventDuplicate
      maxSnack={3}
      dense
      iconVariant={{
        info: '',
      }}
    >
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
