import React from 'react';

import { Chip, ChipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

interface Props {
  points: number;
  className?: string;
  size?: ChipProps['size'];
  plus?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    background: 'radial-gradient(61.07% 61.07% at 50% 50%, #7769CD 0%, #3F51B5 100%)',
    border: '1px solid #B6BDE2',
  },
  pointsBig: {
    fontSize: 16,
    fontWeight: 700,
  },
  points: {
    fontSize: 10,
    fontWeight: 700,
    marginLeft: theme.spacing(1),
    verticalAlign: 'middle',
    textTransform: 'uppercase',
    lineHeight: 1,
  },
}));

const PointsChip = ({ points, className, size = 'small', plus = true }: Props) => {
  const classes = useStyles();

  return (
    <Chip
      label={
        <span className={classes.pointsBig}>
          {plus && '+'}
          {points}
          <span className={classes.points}>
            <FormattedMessage id="rewards.points.label" />
          </span>
        </span>
      }
      size={size}
      className={clsx(classes.root, className)}
    />
  );
};

export default PointsChip;
