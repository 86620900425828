import React, { ReactNode, useEffect, useState } from 'react';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';
import getConfig from 'next/config';
import { useSnackbar } from 'notistack';

import { onMessageListener } from 'utils/firebase.utils';

interface Props {
  children: ReactNode;
}

const { publicRuntimeConfig: config } = getConfig();

function CloudMessagingProvider({ children }: Props) {
  const [notification, setNotification] = useState<{ title: string; body: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [messaging, setMessaging] = useState<Messaging>();

  useEffect(() => {
    const firebaseConfig = {
      apiKey: config.MESSAGING_API_KEY,
      authDomain: config.MESSAGING_AUTH_DOMAIN,
      projectId: config.MESSAGING_PROJECT_ID,
      storageBucket: config.MESSAGING_STORAGE_BUCKET,
      messagingSenderId: config.MESSAGING_SENDER_ID,
      appId: config.MESSAGING_APP_ID,
      measurementId: config.MESSAGING_MEASUREMENT_ID,
    };

    try {
      const app = initializeApp(firebaseConfig);

      setMessaging(getMessaging(app));

      getToken(getMessaging(), {
        vapidKey: config.MESSAGING_VAPID_KEY,
      })
        .then(currentToken => {
          if (currentToken) {
            console.info('current token found');
            // Perform any other neccessary action with the token
          } else {
            // Show permission request UI
            console.info('No registration token available. Request permission to generate one.');
          }
        })
        .catch(err => {
          console.info('An error occurred while retrieving token. ', err);
        });
    } catch (error) {
      console.error('Firebase initialization error', error);
    }
  }, [messaging]);

  if (messaging) {
    onMessageListener(messaging)
      .then(payload => {
        setNotification({
          title: payload?.notification?.title ?? '',
          body: payload?.notification?.body ?? '',
        });
      })
      .catch(err => console.info('failed: ', err));
  }

  useEffect(() => {
    if (notification?.title) {
      enqueueSnackbar(notification?.body);
    }
  }, [enqueueSnackbar, notification]);

  return <>{children}</>;
}

export default CloudMessagingProvider;
