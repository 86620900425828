import { useCallback } from 'react';

import apiService from 'services/api/ApiService';

export const usePendingNotifications = () => {
  const getNotifications = useCallback(() => apiService.getPendingNotifications(), []);

  return {
    getNotifications,
  };
};
