import { alpha } from '@material-ui/core';
import { createCustomMuiTheme } from 'theme/utils/create-mui-theme';

import { isDev } from 'utils/env';

import * as colors from './colors';
import { createOverrides } from './overrides';
import { shadows } from './shadows';
import { states } from './states';
import { typography } from './typography';

const CONFIG_TYPOGRAPHY = {
  BASE_FONT_SIZE: 16,
  RATIO_FACTOR: 1.15,
};

const { background, textPrimary, navigation, search, skeleton, ...restColors } = colors;

let THEME = createCustomMuiTheme({
  breakpoints: {
    values: {
      xs: 300,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  mixins: {},
  palette: {
    type: 'light',
    background,
    text: {
      primary: textPrimary,
      secondary: alpha(textPrimary, 0.6),
      disabled: alpha(textPrimary, 0.38),
    },
    action: {
      active: alpha(textPrimary, 0.54),
      hover: alpha(textPrimary, 0.04),
      hoverOpacity: 0.08,
      selected: alpha(textPrimary, 0.08),
      selectedOpacity: 0.08,
      disabled: alpha(textPrimary, 0.26),
      disabledBackground: alpha(textPrimary, 0.12),
      disabledOpacity: 0.12,
      focus: alpha(textPrimary, 0.12),
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    divider: alpha(textPrimary, 0.12),
    ...restColors,
  },
  zIndex: {
    modal: 2300,
  },
  shadows,
  states,
  shape: {
    borderRadius: 12,
    radius: (factor: number) => 4 * factor,
  },
  spacing: 4,
  typography: {
    fontSize: CONFIG_TYPOGRAPHY.BASE_FONT_SIZE,
    htmlFontSize: CONFIG_TYPOGRAPHY.BASE_FONT_SIZE,

    fontFamily: ['Poppins', 'sans-serif'].join(),
    ...typography,
  },

  props: {
    MuiButtonBase: {
      disableRipple: false,
      disableTouchRipple: false,
      focusRipple: true,
    },
    MuiButton: {
      size: 'large',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiLink: {
      underline: 'always',
    },
    MuiAppBar: {
      elevation: 0,
      position: 'fixed',
      color: 'default',
    },
    MuiAccordion: {
      square: true,
    },
  },
});

THEME.overrides = createOverrides(THEME, { navigation, textPrimary, search, skeleton });

if (isDev()) {
  // NOTE: No skidder log (Skidder not set up yet)
  // console.info('MUI theme created', THEME);
}

export default THEME;
