export function isDev() {
  return process.env.NODE_ENV === 'development';
}

export function isProd() {
  return process.env.NODE_ENV === 'production';
}

export function isMockingEnabled() {
  return process.env.NEXT_PUBLIC_MOCKING_ENABLED === 'true';
}
