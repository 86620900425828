import React, { useEffect, useState } from 'react';

import Router, { useRouter } from 'next/router';

export const RouterContext = React.createContext<string | undefined>(undefined);

const RouterProvider: React.FC = ({ children }) => {
  const { route } = useRouter();
  const [prevRoute, setPreviousRoute] = useState<string>();

  useEffect(() => {
    const handler = () => {
      setPreviousRoute(route);
    };

    Router.events.on('routeChangeComplete', handler);

    return () => {
      Router.events.off('routeChangeComplete', handler);
    };
  }, [route]);

  return <RouterContext.Provider value={prevRoute}>{children}</RouterContext.Provider>;
};

export default RouterProvider;
