import { alpha } from '@material-ui/core';
import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import { createCustomMuiTheme } from 'theme/utils/create-mui-theme';

import { isDev } from 'utils/env';

import defaultTheme from '../theme-default';
import { createOverrides as createDefaultOverrides } from '../theme-default/overrides';
import * as colors from './colors';
import { createOverrides } from './overrides';

const { background, textPrimary, navigation, search, skeleton, ...restColors } = colors;

let THEME = createCustomMuiTheme({
  ..._pick(defaultTheme, [
    'breakpoints',
    'mixins',
    'zIndex',
    'shadows',
    'states',
    'shape',
    'spacing',
    'typography',
    'props',
  ]),
  palette: {
    type: 'dark',
    background,
    text: {
      primary: textPrimary,
      secondary: alpha(textPrimary, 0.6),
      disabled: alpha(textPrimary, 0.38),
    },
    action: {
      active: alpha(textPrimary, 0.54),
      hover: alpha(textPrimary, 0.04),
      hoverOpacity: 0.08,
      selected: alpha(textPrimary, 0.08),
      selectedOpacity: 0.08,
      disabled: alpha(textPrimary, 0.26),
      disabledBackground: alpha(textPrimary, 0.12),
      disabledOpacity: 0.12,
      focus: alpha(textPrimary, 0.12),
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    divider: alpha(textPrimary, 0.12),
    ...restColors,
  },
});

THEME.overrides = _merge(
  createDefaultOverrides(THEME, { navigation, textPrimary, search, skeleton }),
  createOverrides(THEME, { navigation, textPrimary, search, skeleton }),
);

if (isDev()) {
  // NOTE: No skidder log (Skidder not set up yet)
  // console.info('MUI theme created', THEME);
}

export default THEME;
