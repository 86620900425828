export const canShare = () => {
  return typeof window !== 'undefined' ? !!navigator && !!navigator?.share : false;
};

export const canCopy = () => {
  return typeof window !== 'undefined' ? !!navigator && !!navigator?.clipboard : false;
};

export const handleOnShare = (shareData: ShareData, callback?: () => void) => {
  try {
    if (!!navigator && !!navigator?.share) {
      navigator?.share(shareData).then(callback);
    }
  } catch (e) {
    console.info(e);
  }
};

export const handleCopyLink = (copy: string) => {
  if (canCopy()) {
    navigator.clipboard.writeText(copy);
  }
};
