import { AxiosInstance } from 'axios';

import { Credit, CreditTopupOptions } from 'types/credit.types';

/**
 * Get the account's current credit balance.
 * @param api An Axios instance
 * @returns user account credit
 */
export const getAccountCredit = (api: AxiosInstance) => {
  return api.get<Credit>('/account/credit');
};

/**
 * Get a list of available Top Up options for the user's region.
 * @param api An Axios instance
 * @returns topup options
 */
export const getCreditTopupOptions = (api: AxiosInstance) => {
  return api.get<CreditTopupOptions>('/top-up-options');
};

/**
 * Top Up The User's Credit.
 * @param api An Axios instance
 * @returns ok
 */
export const postTopupAccount = (
  api: AxiosInstance,
  amount: number,
  requestId: string,
  siteId: string,
) => {
  return api.post('/account/credit', { amount, requestId, siteId });
};
