// Unnecessary pollyfills for all modern browsers!

// comment out locales that should not be supported
// import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
// import '@formatjs/intl-relativetimeformat/dist/locale-data/be';
// import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';

import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import MESSAGES_EN from './en.json';
import MESSAGES_ES from './es.json';
import MESSAGES_FR from './fr.json';
import MESSAGES_FR_HT from './fr-HT.json';
import MESSAGES_NL from './nl.json';

export const messages: { [key: string]: { [key: string]: string } } = {
  fr: MESSAGES_FR,
  en: MESSAGES_EN,
  es: MESSAGES_ES,
  'fr-HT': MESSAGES_FR_HT,
  nl: MESSAGES_NL,
};

const intlCache = createIntlCache();

const intlEN = createIntl(
  {
    locale: 'en',
    messages: MESSAGES_EN,
  },
  intlCache,
);

const intlFR = createIntl(
  {
    locale: 'fr',
    messages: MESSAGES_FR,
  },
  intlCache,
);

const intlES = createIntl(
  {
    locale: 'es',
    messages: MESSAGES_ES,
  },
  intlCache,
);
const intlFR_HT = createIntl(
  {
    locale: 'fr-HT',
    messages: MESSAGES_FR_HT,
  },
  intlCache,
);
const intlNL = createIntl(
  {
    locale: 'nl',
    messages: MESSAGES_NL,
  },
  intlCache,
);

const intl: { [key: string]: IntlShape } = {
  en: intlEN,
  fr: intlFR,
  es: intlES,
  'fr-HT': intlFR_HT,
  nl: intlNL,
};

export { intl };
