import { useCallback } from 'react';

import { useSnackbar } from 'notistack';

import { PointsNotification } from 'services/api/definitions/rewards.definitions';

import PointsSnackbar from 'components/@rewards/PointsSnackbar';

export const usePointsNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = useCallback(
    (notification: PointsNotification, duration: number = 5000) => {
      enqueueSnackbar(null, {
        autoHideDuration: duration,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        content: key => <PointsSnackbar id={key} {...notification} />,
      });
    },
    [enqueueSnackbar],
  );

  return { showNotification };
};
