import { SimplePaletteColorOptions, Theme } from '@material-ui/core';
import { TypeBackground } from '@material-ui/core/styles/createPalette';

const createOverrides = (
  theme: Theme,
  _colors?: { [key: string]: SimplePaletteColorOptions | string | TypeBackground },
): Theme['overrides'] => ({
  MuiButton: {
    textPrimary: {
      color: theme.palette.text.primary,
      fill: theme.palette.text.primary,
    },
    outlinedPrimary: {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.default,
      '@media (hover: none)': {
        '&:hover': {
          backgroundColor: `${theme.palette.text.primary} !important`,
        },
      },
      '&:hover': {
        backgroundColor: 'white',
        '&:before': {
          backgroundColor: theme.states.primary.outlinedHover,
        },
      },
      '&$disabled': {
        backgroundColor: theme.states.primary.outlinedHover,
      },
    },
  },
});

export { createOverrides };
