import humps, { HumpsProcessorParameter } from 'humps';

const ignoreUppercaseFields = (key: string, convert: HumpsProcessorParameter) =>
  /^[A-Z]+$/.test(key) ? key : convert(key);

export const transformResponse = (data: string) => {
  if (data && data !== 'The service is unavailable') {
    // convert all body keys to camelCase
    return humps.camelizeKeys(JSON.parse(data), ignoreUppercaseFields);
  }

  return null;
};
