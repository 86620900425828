import { AxiosInstance } from 'axios';

import { User, UserDetails } from '../definitions/account.definitions';
import {
  Game,
  GameSsrInfo,
  GameSubscription,
  InfiniteGames,
  LeaderBoards,
} from '../definitions/game.definitions';

/**
 * Get a list of all the games on the platform. (supports infinite scroll)
 * @param api An Axios instance
 * @returns games
 */
export const getAllGames = (api: AxiosInstance, limit?: number, cursor?: string) =>
  api.get<InfiniteGames>('/games', { params: { limit, cursor } });

/**
 * Get a list of game id's and slugnames for server-side rendering.
 * @param api An Axios instance
 * @returns Array of game id's with the slug for SSR
 */
export const getGameSlugs = (api: AxiosInstance) => api.get<GameSsrInfo[]>(`/ssr/games`);

/**
 * Get a specific game or service using the id. Content should be used for the Detail screen.
 * @param api An Axios instance
 * @param id ID of the game
 * @returns Game details
 */
export const getGameById = (api: AxiosInstance, id: string) =>
  api.get<Game | GameSubscription>(`/games/${id}`);

/**
 * Get the current leaderboard and the user's ranking in the current game. This calls the SMS Services API behind the scenes.
 * @param api An Axios instance
 * @param id ID of the game
 * @returns The game's leaderboards
 */
export const getGameLeaderboards = (api: AxiosInstance, id: string) =>
  api.get<LeaderBoards>(`/games/${id}/leaderboards`, { authEnabled: true });

/**
 * Get the external game's URL with a token for the logged in user. Required if the user wants to play games where the flag webview_requires_token is true.
 * @param api An Axios instance
 * @param id The unique identifier of the game or service.
 * @returns The URL of the game including a token.
 */
export const getExternalGameUrl = (api: AxiosInstance, id: string) =>
  api.get<{ url: Game['url'] }>(`/games/${id}/user-url`, { authEnabled: true });

/**
 * Get list of friends that have played this game.
 * @param api An Axios instance
 * @param id The unique identifier of the game or service.
 * @returns Returns a list of friends that have played this game.
 */
export const getFriendsPlaying = (api: AxiosInstance, id: string) =>
  api.get<User[]>(`/games/${id}/friends`, { authEnabled: true });

/**
 * Get a list of tags associated to games.
 * @param api An Axios instance
 * @returns Array of tags
 */
export const getGamesTags = (api: AxiosInstance) =>
  api.get<UserDetails['interests']>(`/games/tags/all`);
