import { QueryKey } from 'react-query';

enum Keys {
  Friends = 'friends',
  TrendingNow = 'trendingNow',
  New = 'new',
  WinRealPrizes = 'winRealPrizes',
  JumpBackIn = 'jumpBackIn',
  Collections = 'collections',
  Account = 'account',
  Trivia = 'games-trivia',
  GameDetail = 'gamedetail',
  GameSlugs = 'gameslugs',
  Scratch = 'scratch',
  Slot = 'slot',
  Voting = 'voting',
  CollectionById = 'collectionbyid',
  AllGames = 'allgames',
  Raffle = 'raffle',
  Favorites = 'favorites',
  Search = 'search',
  Profile = 'profile',
  Subscriptions = 'subscriptions',
  Tags = 'tags',
  Leaderboard = 'leaderboard',
  Rewards = 'rewards',
  PointsTriggers = 'points-triggers',
  Badges = 'badges',
  AvatarsDefault = 'avatars-default',
  AvatarsPremium = 'avatars-premium',
  CreditBalance = 'credit-balance',
  CreditTopupAmounts = 'credit-topup',
  CreditHistory = 'credit-history',
  FeaturedGames = 'featured-games',
  Recommended = 'recommended',
}

export const QueryKeys = {
  friends: {
    all: (...rest: unknown[]) => [Keys.Friends, ...rest] as QueryKey[] | QueryKey,
  },
  favorites: {
    all: (...rest: unknown[]) => [Keys.Favorites, ...rest] as QueryKey[] | QueryKey,
    search: (query: string, ...rest: unknown[]) =>
      [Keys.Favorites, Keys.Search, query, ...rest] as QueryKey[] | QueryKey,
  },
  profile: {
    user: (...rest: unknown[]) => [Keys.Profile, ...rest] as QueryKey[] | QueryKey,
  },
  trendingNow: {
    all: (isLoggedIn: boolean = false, ...rest: unknown[]) =>
      [Keys.TrendingNow, isLoggedIn, ...rest] as QueryKey[] | QueryKey,
  },
  new: {
    all: (...rest: unknown[]) => [Keys.New, ...rest] as QueryKey[] | QueryKey,
  },
  winRealPrizes: {
    all: (...rest: unknown[]) => [Keys.WinRealPrizes, ...rest] as QueryKey[] | QueryKey,
  },
  featuredGames: {
    all: (...rest: unknown[]) => [Keys.FeaturedGames, ...rest] as QueryKey[] | QueryKey,
  },
  jumpBackIn: {
    all: (...rest: unknown[]) => [Keys.JumpBackIn, ...rest] as QueryKey[] | QueryKey,
  },
  recommended: {
    all: (...rest: unknown[]) => [Keys.Recommended, ...rest] as QueryKey[] | QueryKey,
  },
  account: {
    all: (...rest: unknown[]) => [Keys.Account, ...rest] as QueryKey[] | QueryKey,
    unlockedAvatars: () => [Keys.Account, Keys.AvatarsDefault] as QueryKey[] | QueryKey,
  },
  trivia: {
    all: (...rest: unknown[]) => [Keys.Trivia, ...rest] as QueryKey[] | QueryKey,
  },
  gamedetail: {
    all: (...rest: unknown[]) => [Keys.GameDetail, ...rest] as QueryKey[] | QueryKey,
    byId: (id: string, ...rest: unknown[]) =>
      [Keys.GameDetail, id, ...rest] as QueryKey[] | QueryKey,
  },
  gameslugs: {
    all: (...rest: unknown[]) => [Keys.GameSlugs, ...rest] as QueryKey[] | QueryKey,
  },
  scratch: {
    all: (...rest: unknown[]) => [Keys.Scratch, ...rest] as QueryKey[] | QueryKey,
  },
  slot: {
    byId: (id: string) => [Keys.Slot, id] as QueryKey[] | QueryKey,
  },
  voting: {
    all: (...rest: unknown[]) => [Keys.Voting, ...rest] as QueryKey[] | QueryKey,
  },
  raffle: {
    all: (...rest: unknown[]) => [Keys.Raffle, ...rest] as QueryKey[] | QueryKey,
  },
  collections: {
    all: (...rest: unknown[]) => [Keys.Collections, ...rest] as QueryKey[] | QueryKey,
  },
  collectionbyid: {
    all: (...rest: unknown[]) => [Keys.CollectionById, ...rest] as QueryKey[] | QueryKey,
  },
  allgames: {
    all: (...rest: unknown[]) => [Keys.AllGames, ...rest] as QueryKey[] | QueryKey,
    favorites: (...rest: unknown[]) =>
      [Keys.AllGames, Keys.Favorites, ...rest] as QueryKey[] | QueryKey,
    externalurl: (id: string, ...rest: unknown[]) =>
      [Keys.AllGames, id, ...rest] as QueryKey[] | QueryKey,
    friendsplaying: (id: string, ...rest: unknown[]) =>
      [Keys.AllGames, Keys.Friends, id, ...rest] as QueryKey[] | QueryKey,
    leaderboard: (id: string, ...rest: unknown[]) =>
      [Keys.AllGames, Keys.Leaderboard, id, ...rest] as QueryKey[] | QueryKey,
  },
  search: {
    all: (...rest: unknown[]) => [Keys.Search, ...rest] as QueryKey[] | QueryKey,
  },
  subscriptions: {
    all: (...rest: unknown[]) => [Keys.Subscriptions, ...rest] as QueryKey[] | QueryKey,
  },
  tags: {
    all: (...rest: unknown[]) => [Keys.Tags, ...rest] as QueryKey[] | QueryKey,
  },
  rewards: {
    badges: (userId: string, ...rest: unknown[]) =>
      [Keys.Rewards, Keys.Badges, userId, ...rest] as QueryKey[] | QueryKey,
    pointsTriggers: () => [Keys.Rewards, Keys.PointsTriggers] as QueryKey[] | QueryKey,
  },
  avatars: {
    default: () => [Keys.AvatarsDefault] as QueryKey[] | QueryKey,
    premium: () => [Keys.AvatarsPremium] as QueryKey[] | QueryKey,
  },
  credits: {
    balance: () => [Keys.CreditBalance] as QueryKey[] | QueryKey,
    topup: () => [Keys.CreditTopupAmounts] as QueryKey[] | QueryKey,
    history: () => [Keys.CreditHistory] as QueryKey[] | QueryKey,
  },
};
