import { useCallback } from 'react';

import { useMediaQuery } from '@material-ui/core';
import localforage from 'localforage';
import { atom, AtomEffect, DefaultValue, useRecoilState } from 'recoil';

import { AppearanceOptions } from 'types/appearance.types';

interface AppearanceState {
  mode: AppearanceOptions;
}

const DEFAULT_STATE: AppearanceState = {
  mode: AppearanceOptions.Automatic,
};

if (typeof window !== 'undefined') {
  localforage.config({
    driver: localforage.LOCALSTORAGE,
    name: 'digi',
    version: 1.0,
  });
}

const localforageEffect: (key: string) => AtomEffect<AppearanceState> =
  key =>
  ({ setSelf, onSet }) => {
    if (typeof window === 'undefined') {
      return;
    }

    localforage
      .getItem(key)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((value: any) => {
        if (value != null) {
          setSelf(value);
        } else {
          setSelf({ ...DEFAULT_STATE });
        }
      });

    onSet(newValue => {
      if (newValue instanceof DefaultValue) {
        localforage.removeItem(key);
      } else {
        localforage.setItem(key, newValue);
      }
    });
  };

export const appearanceState = atom<AppearanceState>({
  key: 'appearance',
  default: DEFAULT_STATE,
  effects_UNSTABLE: [localforageEffect('appearance')],
});

export const useAppearance = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [appearance, setAppearance] = useRecoilState(appearanceState);

  const setAppearanceMode = useCallback(
    (mode: AppearanceOptions) => {
      setAppearance({ mode });
    },
    [setAppearance],
  );

  let currentAppearance: AppearanceOptions.Dark | AppearanceOptions.Light = prefersDarkMode
    ? AppearanceOptions.Dark
    : AppearanceOptions.Light;

  if (appearance && appearance.mode !== AppearanceOptions.Automatic) {
    currentAppearance = appearance.mode;
  }

  return [currentAppearance, appearance.mode, setAppearanceMode] as const;
};
