import localforage from 'localforage';
import { atom, AtomEffect, DefaultValue } from 'recoil';

import { getRecoil, setRecoil } from 'services/recoil-nexus';
import { AuthState } from 'types/auth.types';

const DEFAULT_STATE: AuthState = {
  accessToken: undefined,
  refreshToken: undefined,
  user: undefined,
  userHydrated: false,
};

if (typeof window !== 'undefined') {
  localforage.config({
    driver: localforage.LOCALSTORAGE,
    name: 'digi',
    version: 1.0,
  });
}

export const getAuth = () => {
  if (typeof window === 'undefined') {
    return DEFAULT_STATE;
  }

  const state = getRecoil(authState);

  return { ...state };
};

export const setAuthTokens = (accessToken: string, refreshToken: string) => {
  const state = getAuth();
  setRecoil(authState, {
    ...state,
    accessToken,
    refreshToken,
  });
};

export const logout = () => {
  setRecoil(authState, { ...DEFAULT_STATE });
};

const localforageAuthEffect: (key: string) => AtomEffect<AuthState> =
  key =>
  ({ setSelf, onSet }) => {
    if (typeof window === 'undefined') {
      return;
    }

    localforage
      .getItem(key)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((value: any) => {
        if (value != null) {
          setSelf(value);
        } else {
          setSelf({ ...DEFAULT_STATE });
        }
      });

    onSet(newValue => {
      if (newValue instanceof DefaultValue) {
        localforage.removeItem(key);
      } else {
        const { userHydrated, ...rest } = newValue;
        // If access token is undefind we should just remove it. User is logged out
        if (rest.accessToken === undefined) {
          localforage.removeItem(key);
        } else {
          localforage.setItem(key, rest);
        }
      }
    });
  };

export const authState = atom<AuthState>({
  key: 'authentication',
  default: DEFAULT_STATE,
  effects_UNSTABLE: [localforageAuthEffect('store')],
});
