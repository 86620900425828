import { AxiosInstance } from 'axios';

import { Avatar, PremiumAvatar } from 'types/avatar.types';

/**
 * Get a list of default avatars, available to all users.
 * @param api An Axios instance
 * @returns default avatars
 */
export const getAvatars = (api: AxiosInstance) => {
  return api.get<Avatar[]>('/avatars');
};

/**
 * Get a list of premium avatars.
 * @param api An Axios instance
 * @returns premium avatars
 */
export const getPremiumAvatars = (api: AxiosInstance) => {
  return api.get<PremiumAvatar[]>('/rewards/premium-avatars', {
    authEnabled: true,
  });
};
