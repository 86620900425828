import { AxiosRequestConfig } from 'axios';
import { getAuth } from 'hooks/auth/util';

import { Headers } from '../../headers';

const success = (connection: AxiosRequestConfig) => {
  const { accessToken } = getAuth();

  //Uncomment once regions are fixed.
  // Always inject accessToken if user is logged in
  if (connection && connection.headers && accessToken) {
    connection.headers[Headers.Authorization] = `Bearer ${accessToken}`;
  }

  // Add default JAM region param if user is not logged in and unauth call
  if (connection && connection.headers && !accessToken && !connection.authEnabled) {
    connection.params = { ...connection.params, region: 'JAM' };
  }

  return connection;
};

const error = (connection: AxiosRequestConfig) => Promise.reject(connection);

export default { success, error };
