import React from 'react';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface Props {
  label: string;
  className?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    color: '#FFF2D0',
    background: theme.palette.warning.main,
    border: '1px solid #FFB800',
    fontSize: 14,
    fontWeight: 700,
    verticalAlign: 'middle',
    textTransform: 'uppercase',
    textShadow:
      '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
  },
}));

const BadgeChip = ({ label, className }: Props) => {
  const classes = useStyles();

  return <Chip label={label} className={clsx(classes.root, className)} size="small" />;
};

export default BadgeChip;
