import axios, { AxiosRequestConfig } from 'axios';
import humps from 'humps';
import qs from 'qs';

import { requestInterceptors, responseInterceptors } from './interceptors';
import { transformRequest } from './transformers/request-transformer';
import { transformResponse } from './transformers/response-transformer';

export const DEFAULT_AXIOS_CONFIG: AxiosRequestConfig = {
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [transformRequest],
  paramsSerializer: params => {
    return qs.stringify(humps.decamelizeKeys(params), { arrayFormat: 'comma', encode: false });
  },
  transformResponse: [transformResponse],
  timeout: 60000,
};

const createManager = (requestConfig?: AxiosRequestConfig) => {
  const manager = axios.create({ ...DEFAULT_AXIOS_CONFIG, ...requestConfig });

  // Request interceptors
  requestInterceptors.forEach(interceptor => {
    manager.interceptors.request.use(interceptor.success, interceptor.error);
  });

  // Response interceptor
  responseInterceptors.forEach(interceptor => {
    manager.interceptors.response.use(interceptor.success, interceptor.error);
  });

  return manager;
};

export { createManager };
