import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import StylesProvider from '@material-ui/styles/StylesProvider';
import { useAppearance } from 'hooks';
import Cookies from 'js-cookie';
import memoize from 'lodash/memoize';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import TagManager from 'react-gtm-module';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import { RecoilRoot } from 'recoil';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { themeDark, themeLight } from 'theme';

import { isDev, isMockingEnabled } from 'utils/env';

import { initSkidder } from 'services';
import { gtmParams } from 'services/analytics';
import { messages } from 'services/i18n/config';
import NotificationsProvider from 'services/notifications';
import CloudMessagingProvider from 'services/notifications/CloudMessagingProvider';
import { SnackbarProvider, SnackbarUtilsConfigurator } from 'services/notistack';
import { queries } from 'services/react-query';
import RecoilNexus from 'services/recoil-nexus';
import RouterProvider from 'services/router/RouterProvider';
import { AppearanceOptions } from 'types/appearance.types';

import { RootBoundary } from 'components/@boundaries';
import Head from 'components/@common/Head';

const initAxe = async () => {
  const ReactDOM = (await import('react-dom')).default;
  const axe = (await import('@axe-core/react')).default;
  axe(React, ReactDOM, 1000);
};

if (isDev() && typeof window !== 'undefined') initAxe();

if (isMockingEnabled()) {
  import('../mocks').then(module => {
    module.initMock();
  });
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const ThemeProvider: React.FC = ({ children }) => {
  const [appearance] = useAppearance();

  const theme = appearance === AppearanceOptions.Dark ? themeDark : themeLight;

  return (
    <>
      {/* Wrap with Material UI theme provider */}
      <MuiThemeProvider theme={theme}>
        {/* Wrap with Styled Components theme provider */}
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </MuiThemeProvider>
    </>
  );
};

function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
  const locale = router.locale ?? router.defaultLocale ?? 'en';
  const [queryClient] = useState(() => new QueryClient({ defaultOptions: { queries } }));

  //https://github.com/facebookexperimental/Recoil/issues/733
  // ignore in-browser next/js recoil warnings until its fixed.
  const mutedConsole = memoize(console => ({
    ...console,
    //@ts-ignore
    warn: (...args) => (args[0].includes('Duplicate atom key') ? null : console.warn(...args)),
  }));
  global.console = mutedConsole(global.console);

  useEffect(() => {
    Cookies.set('NEXT_LOCALE', locale, { expires: 2 });
  }, [locale]);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }

    initSkidder();

    if (!!gtmParams.gtmId) {
      TagManager.initialize(gtmParams);
    }
  }, []);

  const getLayout = Component.getLayout ?? (page => page);

  return (
    <RootBoundary>
      <IntlProvider key={locale} locale={locale} messages={messages[locale]}>
        <QueryClientProvider client={queryClient}>
          <Hydrate
            // @ts-ignore
            state={pageProps.dehydratedState}
          >
            <RecoilRoot>
              <RecoilNexus />
              <StylesProvider injectFirst>
                {/* Wrap with Material UI theme provider */}
                <ThemeProvider>
                  <>
                    {/* Apply Material UI baseline */}
                    <CssBaseline />
                    <RouterProvider>
                      <SnackbarProvider>
                        <SnackbarUtilsConfigurator />
                        <NotificationsProvider>
                          <CloudMessagingProvider>
                            <Head />
                            <meta
                              name="viewport"
                              content="initial-scale=1, viewport-fit=cover, width=device-width"
                            />

                            <style global jsx>{`
                              html {
                                min-height: calc(100% + env(safe-area-inset-top));
                                padding: env(safe-area-inset-top) env(safe-area-inset-right)
                                  env(safe-area-inset-bottom) env(safe-area-inset-left);
                              }
                              html,
                              body,
                              #__next {
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                              }
                              #ot-sdk-btn-floating {
                                display: none !important;
                              }
                            `}</style>
                            {getLayout(<Component {...pageProps} />)}
                          </CloudMessagingProvider>
                        </NotificationsProvider>
                      </SnackbarProvider>
                    </RouterProvider>
                  </>
                </ThemeProvider>
              </StylesProvider>
            </RecoilRoot>
          </Hydrate>
          {isDev() && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
        </QueryClientProvider>
      </IntlProvider>
    </RootBoundary>
  );
}
export default MyApp;
