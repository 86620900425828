import { StateOptions } from '../theme.types';

export const states: StateOptions = {
  primary: {
    containedHover:
      'linear-gradient(0deg, rgba(2, 2, 53, 0.3), rgba(2, 2, 53, 0.3)), linear-gradient(0deg, #3D57D9, #3D57D9)',
    outlinedHover: 'rgba(61, 87, 217, 0.08)',
    outlinedResting: 'rgba(61, 87, 217, 0.5)',
  },
  secondary: {
    containedHover:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),linear-gradient(0deg, #F50057, #F50057)',
    outlinedHover: 'rgba(245, 0, 87, 0.08)',
    outlinedResting: 'rgba(245, 0, 87, 0.5)',
  },
  error: {
    containedHover:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),linear-gradient(0deg, #F44336, #F44336)',
    outlinedHover: 'rgba(244, 67, 54, 0.08)',
    outlinedResting: 'rgba(244, 67, 54, 0.5)',
  },
  info: {
    containedHover:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),linear-gradient(0deg, #2196F3, #2196F3)',
    outlinedResting: 'rgba(33, 150, 243, 0.5)',
    outlinedHover: 'rgba(33, 150, 243, 0.08)',
  },
  success: {
    containedHover:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(0deg, #4CAF50, #4CAF50)',
    outlinedResting: 'rgba(76, 175, 80, 0.5)',
    outlinedHover: 'rgba(76, 175, 80, 0.08)',
  },
  warning: {
    containedHover:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),linear-gradient(0deg, #FF9800, #FF9800)',
    outlinedHover: 'rgba(255, 152, 0, 0.08)',
    outlinedResting: 'rgba(255, 152, 0, 0.5)',
  },
  gradient: {
    containedHover:
      'linear-gradient(0deg, rgba(2, 2, 53, 0.3), rgba(2, 2, 53, 0.3)),linear-gradient(90deg, #D331FB 0%, #37BAFC 100%)',
    outlinedHover: 'rgba(61, 87, 217, 0.08)',
    outlinedResting:
      'linear-gradient(90deg, rgba(211, 49, 251, 0.5) 0%, rgba(55, 186, 252, 0.5) 100%) ',
  },
};
