import { AxiosError } from 'axios';

export const queries = {
  refetchOnWindowFocus: false,
  retry: (failureCount: number, error: unknown) => {
    const status = (error as AxiosError)?.response?.status;
    const code = (error as AxiosError)?.code;
    if (status === 404 || code === 'ECONNABORTED') return false;
    return failureCount < 2;
  },
};
