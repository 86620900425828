import React from 'react';

import { Box, BoxProps, IconButton } from '@material-ui/core';
import Image from 'next/image';

import blurBase64 from 'constants/blurBase64';

import { canShare, handleOnShare } from './util';

interface Props extends ShareData {
  justifyContent?: BoxProps['justifyContent'];
  onShared?: () => void;
}

const Share = ({ justifyContent = 'space-between', onShared, ...rest }: Props) => {
  if (!canShare()) {
    return null;
  }

  return (
    <Box width="100%" display="flex" justifyContent={justifyContent}>
      <IconButton onClick={() => handleOnShare(rest, onShared)} size="small">
        <Image
          src="/social/img-logo-facebook.png"
          height={40}
          width={40}
          priority
          placeholder="blur"
          blurDataURL={blurBase64}
          alt="facebook logo"
        />
      </IconButton>
      <IconButton onClick={() => handleOnShare(rest, onShared)} size="small">
        <Image
          src="/social/img-logo-twitter.png"
          height={40}
          width={40}
          priority
          placeholder="blur"
          blurDataURL={blurBase64}
          alt="facebook logo"
        />
      </IconButton>
      <IconButton onClick={() => handleOnShare(rest, onShared)} size="small">
        <Image
          src="/social/img-logo-whatsapp.png"
          height={40}
          width={40}
          priority
          placeholder="blur"
          blurDataURL={blurBase64}
          alt="facebook logo"
        />
      </IconButton>
      <IconButton onClick={() => handleOnShare(rest, onShared)} size="small">
        <Image
          src="/social/img-logo-messenger.png"
          height={40}
          width={40}
          priority
          placeholder="blur"
          blurDataURL={blurBase64}
          alt="facebook logo"
        />
      </IconButton>
      <IconButton onClick={() => handleOnShare(rest, onShared)} size="small">
        <Image
          src="/social/img-logo-bip.png"
          height={40}
          width={40}
          priority
          placeholder="blur"
          blurDataURL={blurBase64}
          alt="facebook logo"
        />
      </IconButton>
    </Box>
  );
};

export default Share;
