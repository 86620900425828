import { Color, SimplePaletteColorOptions } from '@material-ui/core';
import { TypeBackground } from '@material-ui/core/styles/createPalette';

export const search = '#4f5075';

export const skeleton = '#4f5075';

export const textPrimary = '#ffffff';

export const navigation = '#060e43';

export const background: TypeBackground = { default: '#020235', paper: '#171f5f' };

export const primary: SimplePaletteColorOptions = {
  main: '#3D57D9',
  dark: '#132BA6',
  light: '#748AF6',
  contrastText: '#FFFFFF',
};

export const secondary: SimplePaletteColorOptions = {
  main: '#F50057',
  dark: '#C51162',
  light: '#FF4081',
  contrastText: '#FFFFFF',
};

export const error: SimplePaletteColorOptions = {
  main: '#F44336',
  dark: '#E31B0C',
  light: '#F88078',
  contrastText: '#FFFFFF',
};

export const info: SimplePaletteColorOptions = {
  main: '#2196F3',
  dark: '#0B79D0',
  light: '#64B6F7',
  contrastText: '#FFFFFF',
};

export const warning: SimplePaletteColorOptions = {
  main: '#FF9800',
  dark: '#C77700',
  light: '#FFB547',
  contrastText: 'rgba(0, 0, 0, 0.87)',
};

export const success: SimplePaletteColorOptions = {
  main: '#4CAF50',
  dark: '#3B873E',
  light: '#7BC67E',
  contrastText: '#FFFFFF',
};

export const gradient: SimplePaletteColorOptions = {
  main: 'linear-gradient(90deg, #D331FB 0%, #37BAFC 100%)',
  dark: 'linear-gradient(90deg, #A000C8 0%, #0491D5 100%)',
  light: 'linear-gradient(90deg, #E785FF 0%, #7DD4FF 100%)',
  contrastText: '#FFFFFF',
};

export const grey: Color = {
  50: '#fafafa',
  100: '#F9F9FF',
  200: '#EEEEF6',
  300: '#E2E2EA',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  A100: 'f5f5f5',
  A200: 'eeeeee',
  A400: 'bdbdbd',
  A700: '616161',
};
