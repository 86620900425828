import { useCallback, useEffect } from 'react';

import { useAuth, useMounted } from 'hooks';
import _throttle from 'lodash/throttle';
import { useRouter } from 'next/router';
import { usePendingNotifications } from 'queries';

import { useBadgeNotification, usePointsNotification } from 'services/notifications';

export const useHandleNotifications = () => {
  const { events } = useRouter();
  const { getNotifications } = usePendingNotifications();
  const { isLoggedIn } = useAuth();
  const isMounted = useMounted();
  const { showNotification } = usePointsNotification();
  const { showBadgeNotification } = useBadgeNotification();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkNotifications = useCallback(
    _throttle(
      () => {
        if (isLoggedIn && isMounted) {
          getNotifications().then(data => {
            if (data && !!data.pendingPointsNotifications.length) {
              data.pendingPointsNotifications.forEach((notification, idx) => {
                // Because notistack doesn't support queing https://github.com/iamhosseindhv/notistack/issues/204
                // We just delay the notification until the auto hide has been triggered
                setTimeout(() => {
                  showNotification(notification, 5000);
                }, 6000 * idx);
              });
            }
            const pointsNotificationsCount = data?.pendingPointsNotifications.length ?? 0;
            if (data && !!data.pendingBadgeNotifications.length) {
              // Delay the badges to wait for the points notifications to finish
              setTimeout(() => {
                data.pendingBadgeNotifications.forEach(notification => {
                  showBadgeNotification(notification);
                });
              }, pointsNotificationsCount * 6000);
            }
          });
        }
      },
      // If the user goes fast through routes we don't want to check every time
      10000,
      { leading: true, trailing: false },
    ),
    [isLoggedIn, getNotifications, showNotification, isMounted],
  );

  useEffect(() => {
    checkNotifications();
  }, [checkNotifications]);

  useEffect(() => {
    events.on('routeChangeComplete', () => {
      checkNotifications();
    });
    return () => {
      events.off('routeChangeComplete', () => {});
    };
  }, [events, checkNotifications]);
};
