import { SimplePaletteColorOptions, Theme } from '@material-ui/core';
import { TypeBackground } from '@material-ui/core/styles/createPalette';

const createOverrides = (
  theme: Theme,
  colors?: { [key: string]: SimplePaletteColorOptions | string | TypeBackground },
): Theme['overrides'] => ({
  MuiCssBaseline: {
    '@global': {
      html: {
        // Set relative html font size based
        fontSize: `${(theme.typography.fontSize / 16) * 100}%`,
      },
    },
  },
  MuiSkeleton: {
    root: {
      backgroundColor:
        colors?.skeleton && typeof colors?.skeleton === 'string' ? colors?.skeleton : undefined,
    },
    text: {
      borderRadius: 4,
    },
    rect: {
      borderRadius: 8,
    },
  },

  MuiImageList: {
    root: {
      margin: '0 !important',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
    },
  },
  MuiIconButton: {
    root: {
      fill: theme.palette.text.primary,
    },
  },
  MuiButton: {
    root: {
      height: 36,
      fontWeight: 700,
      borderRadius: 8,
      overflow: 'hidden',
    },
    endIcon: {
      marginLeft: theme.spacing(1),
    },
    textPrimary: {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    containedPrimary: {
      background: theme.palette.gradient.main,
      '&:hover': {
        background: theme.states.gradient.containedHover,
      },
      '&$disabled': {
        background: theme.palette.action.disabledBackground,
      },
    },
    containedSecondary: {
      '&:hover': {
        background: theme.states.secondary.containedHover,
      },
    },
    outlinedPrimary: {
      overflow: 'visible',
      position: 'relative',
      border: '2px solid transparent',
      backgroundColor: theme.palette.background.default,
      borderRadius: 8,
      backgroundClip: 'padding-box',
      color: theme.palette.text.primary,
      '@media (hover: none)': {
        '&:hover': {
          backgroundColor: `${theme.palette.background.default} !important`,
        },
      },
      '&:hover': {
        position: 'relative',
        border: '2px solid transparent',
        borderRadius: 8,
        backgroundClip: 'padding-box',
        backgroundColor: theme.palette.background.default,
        '&:before': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: theme.states.primary.outlinedHover,
          content: '""',
          zIndex: 0,
          borderRadius: 6,
        },
      },
      '&:after': {
        position: 'absolute',
        top: -2,
        left: -2,
        right: -2,
        bottom: -2,
        background: theme.states.gradient.outlinedResting,
        content: '""',
        zIndex: -2,
        borderRadius: 8,
      },
      '&$disabled': {
        border: `2px solid ${theme.palette.action.disabledBackground}`,
        '&& svg': {
          fill: `${theme.palette.action.disabled} !important`,
        },
      },
      '&$disabled:after': {
        display: 'none',
      },
    },
    outlinedSecondary: {
      overflow: 'visible',
      '&& svg': {
        fill: `${theme.palette.secondary.main} !important`,
      },
      '&$disabled': {
        '&& svg': {
          fill: `${theme.palette.action.disabled} !important`,
        },
      },
    },
    sizeSmall: {
      fontSize: 13,
      lineHeight: 1.69,
      letterSpacing: 0.46,
      height: 30,
    },
    sizeLarge: {
      fontSize: 15,
      lineHeight: 1.73,
      letterSpacing: 0.46,
      height: 42,
    },
  },
  MuiChip: {
    label: {
      fontSize: 13,
      lineHeight: 1.38,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  MuiTabs: {
    indicator: {
      backgroundColor: '#3F51B5',
    },
  },
  MuiBottomNavigation: {
    root: {
      backgroundColor:
        colors?.navigation && typeof colors?.navigation === 'string'
          ? colors?.navigation
          : undefined,
      paddingBottom: 'calc(env(safe-area-inset-bottom, 15px) - 15px)',
      height: 'auto',
    },
  },
  MuiCard: {
    root: {
      borderRadius: 12,
    },
  },
  MuiTab: {
    root: {
      fontWeight: 700,
      fontSize: 13,
      lineHeight: 1,
      color: 'rgba(0, 0, 0, 0.6)',
      '&$selected': {
        color: '#3F51B5',
      },
    },
  },
  MuiSnackbarContent: {
    root: {
      borderRadius: 4,
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.17)',
    },
  },
  MuiContainer: {
    root: {
      paddingLeft: `${theme.spacing(4)}px`,
      paddingRight: `${theme.spacing(4)}px`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  MuiAppBar: {
    root: {
      zIndex: theme.zIndex.drawer + 1000,
      paddingTop: 'env(safe-area-inset-top)',
    },
    colorDefault: {
      backgroundColor:
        colors?.navigation && typeof colors?.navigation === 'string'
          ? colors?.navigation
          : undefined,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  MuiAccordion: {
    root: {
      flexShrink: 0,
      padding: 0,
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  },
  MuiAccordionSummary: {
    root: {
      padding: 0,
    },
    expandIcon: {
      marginRight: 0,
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: 0,
      marginBottom: theme.spacing(3),
    },
  },
  MuiToolbar: {
    dense: {
      height: 56,
    },
  },
  MuiDrawer: {
    root: {},
    modal: {},
    paper: {
      paddingTop: 56,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
  },
  MuiDialogContent: {
    root: {
      padding: theme.spacing(4),
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 4,
    },
  },
  MuiOutlinedInput: {
    root: {
      backgroundColor:
        colors?.search && typeof colors?.search === 'string' ? colors?.search : undefined,
      borderRadius: 4,
    },
  },
  MuiSwitch: {
    colorPrimary: {
      color: theme.palette.gradient.main,
      '&$checked': {
        '& .MuiSwitch-thumb': {
          background: theme.palette.gradient.main,
        },
      },
    },
  },
});

export { createOverrides };
