// Request
import authHeader from './request/auth-header.interceptor';
import mock from './request/mock.interceptor';
// Response
import authRefresh from './response/auth-refresh.interceptor';
import timeout from './response/error.interceptor';

// Request interceptors
const requestInterceptors = [authHeader, mock];

// Response interceptors
const responseInterceptors = [authRefresh, timeout];

export { requestInterceptors, responseInterceptors };
