import { captureException } from '@sentry/nextjs';
import { AxiosError, AxiosResponse } from 'axios';
// import Cookies from 'js-cookie';
// import getConfig from 'next/config';
import { Routes } from 'routes';

// import { intl } from 'services/i18n/config';
// import { SnackbarUtils } from 'services/notistack';

// const { publicRuntimeConfig: config } = getConfig();

const success = (response: AxiosResponse) => response;

const error = (error: AxiosError) => {
  // Timeout occured, send error to Sentry
  if (error && error.code === 'ECONNABORTED') {
    captureException(error);
    // TODO: disabled the notification for now, we log it to Sentry so we can check how many timeouts are happening.
    // const locale = Cookies.get('NEXT_LOCALE') ?? config.DEFAULT_LOCALE;
    // SnackbarUtils.warning(
    //   intl[String(locale)].formatMessage({ id: 'general.error_timeout.message' }),
    // );
    return Promise.reject(error);
    // 503 occured(rip), send error to Sentry and redirect to /500
  } else if (error && error.message.includes('unexpected token')) {
    captureException(error);
    window.location.replace(Routes.maintenance);
  }
  return Promise.reject(error);
};

export default { success, error };
