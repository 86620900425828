import { AxiosInstance } from 'axios';

import {
  GetScratchAndWin,
  PlayGameResponse,
  PostRaffleResponse,
  PostScratchAndWin,
  PostSlotMachineResponse,
  PostVotingResponse,
  Raffle,
  SlotMachine,
  SlotMachineBody,
  SpinTheWheel,
  SpinTheWheelBody,
  StartGameResponse,
  Trivia,
  TriviaAnswer,
  TriviaQuestion,
  Voting,
} from '../definitions/play.definitions';

/**
 * Get a single Scratch-And-Win game. This is a dedicated endpoint because the game is native to the platform (i.e. we render and control the UI in the platform).
 * @param api An Axios instance
 * @param id The unique identifier of this Scratch-And-Win game.
 * @returns Config on how to render the game
 */
export const getScratchAndWin = (api: AxiosInstance, id: string) => {
  return api.get<GetScratchAndWin>(`/play/scratch-and-win/${id}`, { authEnabled: true });
};

/**
 * Play a single Scratch-And-Win game. Returns the card's content and also immediately the result of the game session.
 * @param api An Axios instance
 * @param id The unique identifier of this Scratch-And-Win game.
 * @returns The result of the game
 */
export const postScratchAndWin = (api: AxiosInstance, id: string) => {
  return api.post<PostScratchAndWin>(`/play/scratch-and-win/${id}`, undefined, {
    authEnabled: true,
  });
};

/**
 * Get a single Slot-machine game. This is a dedicated endpoint because the game is native to the platform (i.e. we render and control the UI in the platform).
 * @param api An Axios instance
 * @param id The unique identifier of this Slot game.
 * @returns Config on how to render the game
 */
export const getSlotMachine = (api: AxiosInstance, id: string) => {
  return api.get<SlotMachine>(`/play/slot/${id}`);
};

/**
 * Play a single Slot machine game. Returns the result of the game session.
 * @param api An Axios instance
 * @param id The unique identifier of this Slot game.
 * @param body Slot machine body
 * @returns The result of the game
 */
export const postSlotMachine = (api: AxiosInstance, id: string, body: SlotMachineBody) => {
  return api.post<PostSlotMachineResponse>(`/play/slot/${id}`, body);
};

/**
 * Get a single Spin-The-Wheel game. This is a dedicated endpoint because the game is native to the platform (i.e. we render and control the UI in the platform).
 * @param api An Axios instance
 * @param theme The theme that should be used to render this Spin The Wheel game.
 * @returns Config on how to render the game
 */
export const getSpinTheWheel = (api: AxiosInstance, theme?: string) => {
  return api.get<SpinTheWheel>('/play/spin-the-wheel', { params: { theme } });
};

/**
 * Play a single Spin-The-Wheel game. Returns the result of the game session.
 * @param api An Axios instance
 * @param body Spin the wheel body
 * @returns The result of the game
 */
export const postSpinTheWheel = (api: AxiosInstance, body: SpinTheWheelBody) => {
  return api.post<PlayGameResponse>('/play/spin-the-wheel', body);
};

/**
 * Get a single Trivia game. This is a dedicated endpoint because the game is native to the platform (i.e. we render and control the UI in the platform).
 * @param api An Axios instance
 * @param id The unique identifier of this Trivia game.
 * @returns Config on how to render the game
 */
export const getTrivia = (api: AxiosInstance, id: string) => {
  return api.get<Trivia>(`/play/quiz/${id}`, { authEnabled: true });
};

export const postTriviaQuestion = (api: AxiosInstance, id: string) => {
  return api.post<TriviaQuestion>(`/play/quiz/${id}`, undefined, { authEnabled: true });
};

export const postTriviaAnswer = (api: AxiosInstance, id: string, answer: string) => {
  return api.post<TriviaAnswer>(`/play/quiz/${id}/answer`, answer, {
    authEnabled: true,
    headers: {
      'Content-Type': 'text/plain',
    },
  });
};

/**
 * Play a single Voting game. This is a dedicated endpoint because the game is native to the platform (i.e. we render and control the UI in the platform).
 * @param api An Axios instance
 * @param id The unique identifier of this Voting game.
 * @returns Config on how to render the game
 */
export const getVoting = (api: AxiosInstance, id: string) => {
  return api.get<Voting>(`/play/vote/${id}`, { authEnabled: true });
};

export const postVoting = (api: AxiosInstance, id: string, optionId: string, amount: number) => {
  return api.post<PostVotingResponse>(
    `/play/vote/${id}`,
    { option_id: optionId, amount },
    { authEnabled: true },
  );
};

/**
 * Play a single raffle game. This is a dedicated endpoint because the game is native to the platform (i.e. we render and control the UI in the platform).
 * @param api An Axios instance
 * @param id The unique identifier of this Voting game.
 * @returns Config on how to render the game
 */
export const getRaffle = (api: AxiosInstance, id: string) => {
  return api.get<Raffle>(`/play/raffle/${id}`, { authEnabled: true });
};

export const postRaffle = (api: AxiosInstance, id: string, amount: number) => {
  return api.post<PostRaffleResponse>(`/play/raffle/${id}`, { amount }, { authEnabled: true });
};

/**
 * Invoke this endpoint to indicate that a user started playing a game. Used for tracking, points, etc.
 * @param api An Axios instance
 * @param id The id of the game.
 * @param name The name of the game.
 * @returns Notifications to show to the user
 */
export const startGame = (api: AxiosInstance, id: string, name: string) => {
  return api.post<StartGameResponse>(`/play/${id}`, { name }, { authEnabled: true });
};
