import { useCallback } from 'react';

import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { QueryKeys } from 'queries';
import { useMutation, useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { Routes } from 'routes';

import apiService from 'services/api/ApiService';
import { GetAccountResponse } from 'services/api/definitions/account.definitions';

import { authState, logout } from './util';

export const useAuth = () => {
  const router = useRouter();
  const [auth, setAuth] = useRecoilState(authState);
  const isLoggedIn = auth.accessToken !== undefined && auth.accessToken.length > 0;

  const { mutate: mutateToken } = useMutation(
    (code: string) => apiService.getDigicelIDAccessToken(code),
    {
      onSuccess: ({ accessToken, refreshToken }) => {
        setAuth({ accessToken, refreshToken });
      },
    },
  );

  const { isLoading, error, status } = useQuery<unknown, AxiosError, GetAccountResponse | null>(
    QueryKeys.account.all(),
    () => apiService.getAccountInfo(),
    {
      enabled: isLoggedIn && (!auth.user || !auth.userHydrated),
      onSuccess: response => {
        if (response) {
          setAuth({ ...auth, user: response, userHydrated: true });
        }
      },
      onError: error => {
        if (error && error.request?.status === 403) {
          router.replace(Routes.onboarding);
        }
      },
      retry: false,
    },
  );

  const authWithCode = useCallback(
    (code: string) => {
      mutateToken(encodeURIComponent(code));
    },
    [mutateToken],
  );

  const handlelogout = useCallback(() => {
    logout();
  }, []);

  return {
    logout: handlelogout,
    authWithCode,
    isLoggedIn,
    user: auth.user,
    error,
    status,
    isLoading,
    setAuth,
    auth,
  };
};
