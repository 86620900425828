import { FC } from 'react';

import * as Sentry from '@sentry/nextjs';

interface FallbackProps {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}

// Since nothing is loaded at this point the fallback can only return basic HTML
// Do not attempt to translate this error or add styles, they are not loaded yet!
const Fallback: React.FC<FallbackProps> = ({ error, componentStack }) => {
  const message = error?.message;
  return (
    <>
      <h1>Whoopsie</h1>
      {message && <p>{message}</p>}
      <pre>{componentStack}</pre>
    </>
  );
};

// This is the everything else failed fallback and is normally never shown to the user
// If there is an issue with the connectors then this will make sure we see it in sentry
const RootBoundary: FC = ({ children }) => (
  <Sentry.ErrorBoundary
    beforeCapture={scope => scope.setTag('boundary', 'root')}
    fallback={props => <Fallback {...props} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default RootBoundary;
