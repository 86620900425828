export enum Events {
  friendRequest = 'friendRequest',
  approveFriendRequest = 'approveFriendRequest',
  login = 'login',
  startRegistration = 'startRegistration',
  startOnboarding = 'startOnboarding',
  completeOnboarding = 'completeOnboarding',
  earnBadge = 'earnBadge',
  shareBadge = 'shareBadge',
  redeemReward = 'redeemReward',
  gameSeen = 'gameSeen',
  gamePlayed = 'gamePlayed',
  consultPoints = 'consultPoints',
  searchDetailClick = 'searchDetailClick',
  searchNotFound = 'searchNotFound',
  loginPWA = 'loginPWA',
}
