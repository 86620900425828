import { AxiosInstance } from 'axios';

import { Avatar, PremiumAvatar, UnlockedRewards } from 'types/avatar.types';

import {
  CreateAccountResponse,
  GetAccountResponse,
  GetFriendsResponse,
  GetSubscriptionsResponse,
  SubscriptionResponse,
  UpdateAccountBody,
  UpdateAccountResponse,
  UserDetails,
} from '../definitions/account.definitions';
import { GameTeaser } from '../definitions/collections.definitions';

/**
 * Gets the current user's account info.
 * @param api An Axios instance
 * @returns user
 */
export const getAccountInfo = (api: AxiosInstance) =>
  api.get<GetAccountResponse>('/account', {
    authEnabled: true,
  });

/**
 * Creates a new "platform account" with the nickname and avatar in the request.
 * @param api An Axios instance
 * @returns user
 */
export const createNewAccount = (api: AxiosInstance, username: string, avatar: string) =>
  api.post<CreateAccountResponse>(
    '/account',
    {
      username,
      avatar,
    },
    { authEnabled: true },
  );

/**
 * Update account information for the user.
 * @param api An Axios instance
 */
export const updateAccountInfo = (api: AxiosInstance, body: UpdateAccountBody) => {
  return api.put<UpdateAccountResponse>(`/account `, body, { authEnabled: true });
};

/**
 * Get a list of the current user's friends.
 * @param api An Axios instance
 * @returns A list of users
 */
export const getFriends = (api: AxiosInstance) =>
  api.get<GetFriendsResponse>('/account/friends', { authEnabled: true });

/**
 * Send a friend request to the given user id.
 * @param api An Axios instance
 */
export const addFriend = (api: AxiosInstance, id: string) => {
  return api.post(`/account/friends/${id}`, undefined, { authEnabled: true });
};

/**
 * Remove the given user from your friends.
 * @param api An Axios instance
 */
export const deleteFriend = (api: AxiosInstance, id: string) => {
  return api.delete(`/account/friends/${id}`, { authEnabled: true });
};

/**
 * Get profile information for the supplied user id.
 * @param api An Axios instance
 */
export const getProfileInformation = (api: AxiosInstance, username: string) => {
  return api.get<UserDetails>(`/account/${username}/profile`, { authEnabled: true });
};

/**
 * Accept a friendrequest from the user with the given {id}.
 * @param api An Axios instance
 */
export const acceptFriendRequest = (api: AxiosInstance, id: string) => {
  return api.post(`/account/friends/${id}/accept`, undefined, { authEnabled: true });
};

/**
 * Deny a friendrequest from the user with the given {id}.
 * @param api An Axios instance
 */
export const denyFriendRequest = (api: AxiosInstance, id: string) => {
  return api.post(`/account/friends/${id}/deny`, undefined, { authEnabled: true });
};

/**
 * Get a list of the current user's favorite games.
 * @param api An Axios instance
 */
export const getFavoriteGames = (api: AxiosInstance) => {
  return api.get<GameTeaser[]>(`/account/favorites`, { authEnabled: true });
};

/**
 * Search the list of the current user's favorite games.
 * @param api An Axios instance
 */
export const searchFavoriteGames = (api: AxiosInstance, term: string) => {
  return api.get<GameTeaser[]>(`/account/favorites/search`, {
    params: { term },
    authEnabled: true,
  });
};

/**
 * Mark a game with the given id as a favorite.
 * @param api An Axios instance
 */
export const addFavoriteGame = (api: AxiosInstance, id: string) => {
  return api.post(`/account/favorites/${id}`, undefined, { authEnabled: true });
};

/**
 * Remove a game with the given id from the user's favorites.
 * @param api An Axios instance
 */
export const deleteFavoriteGame = (api: AxiosInstance, id: string) => {
  return api.delete(`/account/favorites/${id}`, { authEnabled: true });
};

/**
 * Add the tag to the user's favorites.
 * @param api An Axios instance
 */
export const addTag = (api: AxiosInstance, tag: string) => {
  return api.post(`/account/interests/${tag}`, undefined, { authEnabled: true });
};

/**
 * Remove the tag from the user's favorites.
 * @param api An Axios instance
 */
export const removeTag = (api: AxiosInstance, tag: string) => {
  return api.delete(`/account/interests/${tag}`, { authEnabled: true });
};

/**
 * Get a list of the current user's subscriptions and a list of available subscriptions.
 * @param api An Axios instance
 */
export const getSubscriptions = (api: AxiosInstance) => {
  return api.get<GetSubscriptionsResponse>(`/account/subscriptions`, {
    authEnabled: true,
  });
};

/**
 * Unsubscribe from a subscription
 * @param api An Axios instance
 * @param url Url to unsubscribe
 */
export const unsubscribeFromService = (api: AxiosInstance, url: string) => {
  return api.get<SubscriptionResponse>(url);
};

/**
 * Subscribe to a subscription
 * @param api An Axios instance
 * @param url Url to subscribe
 */
export const subscribeToService = (api: AxiosInstance, url: string) => {
  return api.get<SubscriptionResponse>(url);
};

/**
 * Unlock a reward, adding it to your account.
 * @param api An Axios instance
 * @param url Url to subscribe
 */
export const proxySubscriptionEventUrl = (api: AxiosInstance, url: string) => {
  return api.post(
    `/subscriptions`,
    { url },
    {
      authEnabled: true,
    },
  );
};

/**
 * Unlock a reward, adding it to your account.
 * @param api An Axios instance
 */
export const unlockReward = (api: AxiosInstance, id: Avatar['name']) => {
  return api.post<PremiumAvatar>(
    `/account/rewards`,
    { id },
    {
      authEnabled: true,
    },
  );
};

/**
 * Get an overview of unlocked rewards for this user.
 * @param api An Axios instance
 */
export const getUnlockedRewards = (api: AxiosInstance) => {
  return api.get<UnlockedRewards>(`/account/rewards`, {
    authEnabled: true,
  });
};
