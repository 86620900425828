import React, { Component } from 'react';

import { BadgeNotification } from 'services/api/definitions/rewards.definitions';

import BadgeEarnedModal from 'components/@rewards/BadgeEarnedModal';

interface State {
  isOpen: boolean;
  activeNotification?: BadgeNotification;
}

export const BadgeNotificationContext = React.createContext<
  React.RefObject<BadgeNotificationManager> | undefined
>(undefined);

export class BadgeNotificationManager extends Component<{}, State> {
  private queue: BadgeNotification[] = [];

  constructor(props: {}) {
    super(props);
    this.state = { isOpen: false };
  }

  addNotification = (notification: BadgeNotification) => {
    this.queue.push(notification);

    if (this.queue.length === 1) {
      this.setState({ activeNotification: notification, isOpen: true });
    }
  };

  private handleClose = () => {
    this.setState({ isOpen: false, activeNotification: undefined }, () => {
      setTimeout(() => {
        this.queue.shift();
        if (this.queue.at(0)) {
          this.setState({ activeNotification: this.queue.at(0), isOpen: true });
        }
      }, 1500);
    });
  };

  render() {
    const { isOpen, activeNotification } = this.state;

    return (
      <BadgeEarnedModal
        isOpen={isOpen}
        onClose={this.handleClose}
        notification={activeNotification}
      />
    );
  }
}

export default BadgeNotificationManager;
