import getConfig from 'next/config';

import { Avatar } from 'types/avatar.types';

import { createManager } from './axios';
import { UpdateAccountBody } from './definitions/account.definitions';
import {
  acceptFriendRequest,
  addFavoriteGame,
  addFriend,
  addTag,
  createNewAccount,
  deleteFavoriteGame,
  deleteFriend,
  denyFriendRequest,
  getAccountInfo,
  getFavoriteGames,
  getFriends,
  getProfileInformation,
  getSubscriptions,
  getUnlockedRewards,
  proxySubscriptionEventUrl,
  removeTag,
  searchFavoriteGames,
  subscribeToService,
  unlockReward,
  unsubscribeFromService,
  updateAccountInfo,
} from './requests/account.api';
import {
  getDigicelIDAccessToken,
  refreshDigicelIDAccessToken,
} from './requests/authentication.api';
import { getAvatars, getPremiumAvatars } from './requests/avatar.api';
import {
  geRecommended,
  getCollectionById,
  getCollections,
  getFeaturedGames,
  getJumpBackIn,
  getNew,
  getTrendingNow,
  getWinRealPrizes,
} from './requests/collections.api';
import { getAccountCredit, getCreditTopupOptions, postTopupAccount } from './requests/credit.api';
import { createPWAEvent } from './requests/events.api';
import {
  getAllGames,
  getExternalGameUrl,
  getFriendsPlaying,
  getGameById,
  getGameLeaderboards,
  getGameSlugs,
  getGamesTags,
} from './requests/games.api';
import {
  getRaffle,
  getScratchAndWin,
  getSlotMachine,
  getSpinTheWheel,
  getTrivia,
  getVoting,
  postRaffle,
  postScratchAndWin,
  postSlotMachine,
  postSpinTheWheel,
  postTriviaAnswer,
  postTriviaQuestion,
  postVoting,
  startGame,
} from './requests/play.api';
import { getBadges, getPendingNotifications, getPointsTriggers } from './requests/rewards.api';
import { search } from './requests/search.api';

const { publicRuntimeConfig: config } = getConfig();

export class ApiService {
  private api;

  constructor() {
    const apiManager = createManager({ baseURL: config.API_BASE });
    this.api = apiManager;
  }

  /* - Games - */
  public async getGameSlugs() {
    try {
      const response = await getGameSlugs(this.api);
      return response.data;
    } catch {
      return [];
    }
  }

  public async getGameDetails(id: string) {
    const response = await getGameById(this.api, id);

    return response.data;
  }

  public async getGameLeaderboards(id: string) {
    const response = await getGameLeaderboards(this.api, id);
    return response.data;
  }

  public async getAllGames(limit?: number, cursor?: string) {
    const response = await getAllGames(this.api, limit, cursor);
    return response.data;
  }

  public async getExternalGameUrl(id: string) {
    const response = await getExternalGameUrl(this.api, id);
    return response.data;
  }

  public async getGamesTags() {
    const response = await getGamesTags(this.api);
    return response.data;
  }

  public async getFriendsPlaying(id: string) {
    const response = await getFriendsPlaying(this.api, id);
    return response.data;
  }

  /* - Game Play - */

  public async startGame(id: string, name: string) {
    const response = await startGame(this.api, id, name);
    return response.data;
  }

  public async getScratchAndWin(id: string) {
    const response = await getScratchAndWin(this.api, id);
    return response.data;
  }

  public async playScratchAndWin(id: string) {
    const response = await postScratchAndWin(this.api, id);
    return response.data;
  }

  public async getSpinTheWheelConfig(theme?: string) {
    const response = await getSpinTheWheel(this.api, theme);
    return response.data;
  }

  public async playSpinTheWheel(numberOfSpins: number) {
    const response = await postSpinTheWheel(this.api, { numberOfSpins });
    return response.data;
  }

  public async getSlotMachine(id: string) {
    const response = await getSlotMachine(this.api, id);
    return response.data;
  }

  public async playSlotMachine(id: string, amount: number) {
    const response = await postSlotMachine(this.api, id, { entryCount: amount });
    return response.data;
  }

  public async getTrivia(id: string) {
    const response = await getTrivia(this.api, id);
    return response.data;
  }

  public async playTrivia(id: string) {
    const response = await postTriviaQuestion(this.api, id);
    return response.data;
  }

  public async answerTrivia(id: string, answer: string) {
    const response = await postTriviaAnswer(this.api, id, answer);
    return response.data;
  }

  public async getRaffle(id: string) {
    const response = await getRaffle(this.api, id);
    return response.data;
  }

  public async playRaffle(id: string, amount: number) {
    const response = await postRaffle(this.api, id, amount);
    return response.data;
  }

  public async getVoting(id: string) {
    const response = await getVoting(this.api, id);
    return response.data;
  }

  public async playVoting(id: string, optionId: string, amount: number) {
    const response = await postVoting(this.api, id, optionId, amount);
    return response.data;
  }

  /* - Search - */
  public async search(term: string, collectionId?: string) {
    const response = await search(this.api, term, collectionId);
    return response.data;
  }

  /* - Collections - */
  public async getCollections() {
    const response = await getCollections(this.api);
    return response.data;
  }

  public async getJumpBackInCollection() {
    try {
      const response = await getJumpBackIn(this.api);
      return response.data;
    } catch {
      return [];
    }
  }

  public async getNewCollection(limit?: number) {
    try {
      const response = await getNew(this.api, limit);
      return response.data;
    } catch {
      return [];
    }
  }

  public async getTrendingNowCollection() {
    try {
      const response = await getTrendingNow(this.api);
      return response.data;
    } catch {
      return [];
    }
  }

  public async getWinRealPrizesCollection(limit?: number) {
    try {
      const response = await getWinRealPrizes(this.api, limit);
      return response.data;
    } catch {
      return [];
    }
  }

  public async getGameCollection(id: string, cursor?: string) {
    const response = await getCollectionById(this.api, id, cursor);
    return response.data;
  }

  public async getFeaturedGames() {
    const response = await getFeaturedGames(this.api);
    return response.data;
  }

  public async getRecommended() {
    const response = await geRecommended(this.api);
    return response.data;
  }

  /* - Credit - */
  public async postTopupAccount(amount: number, requestId: string, siteId: string) {
    const response = await postTopupAccount(this.api, amount, requestId, siteId);
    return response.data;
  }

  public async getAccountCredit() {
    const response = await getAccountCredit(this.api);
    return response.data;
  }

  public async getCreditTopupOptions() {
    const response = await getCreditTopupOptions(this.api);
    return response.data;
  }

  /* - Account - */
  public async getAccountInfo() {
    const response = await getAccountInfo(this.api);
    return response.data;
  }

  public async createNewAccount(username: string, avatar: string) {
    return await createNewAccount(this.api, username, avatar);
  }

  public async updateAccountInfo(body: UpdateAccountBody) {
    const response = await updateAccountInfo(this.api, body);
    return response.data;
  }

  public async getUnlockedRewards() {
    return (await getUnlockedRewards(this.api)).data;
  }

  public async unlockReward(id: Avatar['name']) {
    return (await unlockReward(this.api, id)).data;
  }

  public async getFriends() {
    const response = await getFriends(this.api);
    return response.data;
  }

  public async getProfileInformation(username: string) {
    const response = await getProfileInformation(this.api, username);
    return response.data;
  }

  public async addFriend(username: string) {
    const response = await addFriend(this.api, username);
    return response.data;
  }

  public async deleteFriend(id: string) {
    const response = await deleteFriend(this.api, id);
    return response.data;
  }

  public async acceptFriendRequest(id: string) {
    const response = await acceptFriendRequest(this.api, id);
    return response.data;
  }

  public async denyFriendRequest(id: string) {
    const response = await denyFriendRequest(this.api, id);
    return response.data;
  }

  public async getFavoriteGames() {
    const response = await getFavoriteGames(this.api);
    return response.data;
  }

  public async searchFavoriteGames(term: string) {
    const response = await searchFavoriteGames(this.api, term);
    return response.data;
  }

  public async addFavoriteGame(id: string) {
    const response = await addFavoriteGame(this.api, id);
    return response.data;
  }

  public async deleteFavoriteGame(id: string) {
    const response = await deleteFavoriteGame(this.api, id);
    return response.data;
  }

  public async addTag(tag: string) {
    const response = await addTag(this.api, tag);
    return response.data;
  }

  public async removeTag(tag: string) {
    const response = await removeTag(this.api, tag);
    return response.data;
  }

  /* - Subscriptions - */
  public async getSubscriptions() {
    const response = await getSubscriptions(this.api);
    return response.data;
  }

  public async unsubscribeFromService(url: string) {
    const response = await unsubscribeFromService(this.api, url);
    return response.data;
  }

  public async subscribeTomService(url: string) {
    const response = await subscribeToService(this.api, url);
    return response.data;
  }

  public async proxySubscriptionEventUrl(url: string) {
    const response = await proxySubscriptionEventUrl(this.api, url);
    return response.data;
  }

  /* - Authentication - */
  public async getDigicelIDAccessToken(authorizationToken: string) {
    const response = await getDigicelIDAccessToken(this.api, authorizationToken);
    return response.data;
  }

  public async refreshDigicelIDAccessToken(refreshToken: string) {
    const response = await refreshDigicelIDAccessToken(this.api, refreshToken);
    return response.data;
  }

  /* - Rewards - */
  public async getPendingNotifications() {
    const response = await getPendingNotifications(this.api);
    return response.data;
  }

  public async getPointsTriggers() {
    const response = await getPointsTriggers(this.api);
    return response.data;
  }

  public async getBadges(userId: string) {
    const response = await getBadges(this.api, userId);
    return response.data;
  }

  /* - Avatars - */
  public async getAvatars() {
    return (await getAvatars(this.api)).data;
  }

  public async getPremiumAvatars() {
    return (await getPremiumAvatars(this.api)).data;
  }

  /* - events - */
  public async createPWAEvent() {
    return await createPWAEvent(this.api);
  }
}

const apiService = new ApiService();

export default apiService;
