import React, { forwardRef } from 'react';

import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import { SnackbarContent, SnackbarKey, useSnackbar } from 'notistack';
import { FormattedMessage, useIntl } from 'react-intl';
import { Routes } from 'routes';

import { PointsNotification } from 'services/api/definitions/rewards.definitions';

import Link from 'components/@navigation/Link';

import PointsChip from '../PointsChip';

interface Props extends PointsNotification {
  id: SnackbarKey;
  className?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    width: '100%',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
    padding: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.breakpoints.values.md,
    },
    background: theme.palette.warning.main,
    boxShadow: '0px -3px 8px rgba(0, 0, 0, 0.25)',
  },
  chip: {
    boxShadow: '0px 0px 15px #BA7300',
  },
  confetti: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 115,
    height: '100%',
    backgroundImage: `url(/rewards/img-confetti-background-card-2.png)`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
}));

const PointsSnackbar = forwardRef<HTMLDivElement, Props>(
  ({ id, pointsGained, title, message, values = {}, className }, ref) => {
    const classes = useStyles();
    const intl = useIntl();
    const { closeSnackbar } = useSnackbar();
    return (
      <SnackbarContent ref={ref} className={clsx(classes.root, className)}>
        <div className={classes.confetti} />
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="flex-start">
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1">{intl.formatMessage({ id: title }, values)}</Typography>
            <Typography variant="caption">{intl.formatMessage({ id: message }, values)}</Typography>
          </Box>
          <IconButton onClick={() => closeSnackbar(id)} size="small" color="inherit" edge="end">
            <Close />
          </IconButton>
        </Box>
        <Box
          mt={2}
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="flex-start"
        >
          <PointsChip points={pointsGained} className={classes.chip} />
          <Button
            component={Link}
            variant="outlined"
            color="inherit"
            size="small"
            href={Routes.profile}
          >
            <FormattedMessage id="rewards.notification.points.view.button" />
          </Button>
        </Box>
      </SnackbarContent>
    );
  },
);
PointsSnackbar.displayName = 'PointsSnackbar';

export default PointsSnackbar;
