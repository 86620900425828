import skidder, {
  logInfo,
  SkidderLogLevel,
  SkidderServiceConsole,
} from '@novemberfiveco/skidder-react';
import process from 'process';

import { isDev } from 'utils/env';

export function initSkidder() {
  // TODO: Needs to be fixed in Skidder v1
  const consoleService = new SkidderServiceConsole(
    'console_1',
    isDev() ? SkidderLogLevel.trace : SkidderLogLevel.warn,
  );
  skidder.addService(consoleService);

  skidder.setMetaDataRecord('environment', process.env.REACT_APP_BUILD_ENV ?? 'n/a');

  logInfo('Did setup Skidder');
}
