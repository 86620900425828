import humps from 'humps';

export const transformRequest = (data: { [key: string]: (key: string) => string }) => {
  let output: string | object = humps.decamelizeKeys(data);

  // convert all body objects to strings
  if (typeof data === 'object') {
    output = JSON.stringify(output);
  }

  return output;
};
