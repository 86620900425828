import { useCallback, useState } from 'react';

import _isEqual from 'lodash/isEqual';
import TagManager from 'react-gtm-module';

import { Events } from 'constants/events';

import { useAuth } from './auth';

interface Params {
  event?: Events;
  data?: {};
  withUser?: boolean;
  once?: boolean;
}

export const useAnalytics = () => {
  const { user, isLoggedIn } = useAuth();
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const [prevTrackData, setPrevTrackData] = useState<object | undefined>();

  const send = useCallback(
    ({ event, data = {}, once = true }: Params) => {
      let trackData: {} = { event, ...data };
      if (isLoggedIn && !!user) {
        trackData = {
          event,
          ...data,
          user: { id: user.id, email: user.email, username: user.username },
        };
      }
      if (
        (once && !hasBeenCalled) ||
        !once ||
        (prevTrackData && !_isEqual(trackData, prevTrackData))
      ) {
        setHasBeenCalled(true);
        setPrevTrackData(trackData);
        TagManager.dataLayer({ dataLayer: { ...trackData } });
      }
    },
    [isLoggedIn, user, hasBeenCalled, prevTrackData],
  );

  return {
    send,
  };
};
