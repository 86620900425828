import React from 'react';

import { Box, Button, Dialog, IconButton, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useAnalytics } from 'hooks';
import Image from 'next/image';
import { FormattedMessage, useIntl } from 'react-intl';
import { Routes } from 'routes';

import { Events } from 'constants/events';
import { BadgeNotification } from 'services/api/definitions/rewards.definitions';

import Share from 'components/@common/Share';
import { canShare } from 'components/@common/Share/util';
import Link from 'components/@navigation/Link';

import {
  BadgeChipStyled,
  DialogContentStyled,
  ImageWrap,
  TextWrap,
} from './BadgeEarnedModal.style';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  notification?: BadgeNotification;
}

const BadgeEarnedModal = ({ isOpen, onClose, notification }: Props) => {
  const intl = useIntl();
  const { send } = useAnalytics();

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      {notification && (
        <DialogContentStyled>
          <Image
            layout="fill"
            src="/rewards/img-confetti-background-card-3.png"
            objectFit="contain"
            objectPosition="50% 0"
            alt="background"
          />
          <Box width="100%" display="flex" justifyContent="flex-end">
            <IconButton edge="end" onClick={onClose} aria-label="close" color="inherit">
              <Close />
            </IconButton>
          </Box>
          <ImageWrap>
            <Image
              src={notification.badgeImage}
              width={125}
              height={125}
              objectFit="contain"
              priority
              alt="badge"
            />
          </ImageWrap>
          <Box mt={8} mb={4}>
            <BadgeChipStyled label={intl.formatMessage({ id: notification.title })} />
          </Box>
          <TextWrap>
            <Typography variant="h5">
              {intl.formatMessage({ id: notification.badgeName })}
            </Typography>
            <Box mt={2}>
              <Typography variant="body1">
                {intl.formatMessage({ id: notification.message })}
              </Typography>
            </Box>
          </TextWrap>
          {canShare() && (
            <>
              <Box mt={4}>
                <Typography variant="overline">
                  <FormattedMessage id="rewards.notification.badge.share.label" />
                </Typography>
              </Box>
              <Share
                title={intl.formatMessage(
                  { id: 'rewards.notification.badge.share.title' },
                  { badge: intl.formatMessage({ id: notification.badgeName }) },
                )}
                text={intl.formatMessage(
                  { id: 'rewards.notification.badge.share.body' },
                  { badge: intl.formatMessage({ id: notification.badgeName }) },
                )}
                justifyContent="center"
                onShared={() =>
                  send({
                    event: Events.shareBadge,
                    data: { badge: { name: notification.badgeName } },
                    withUser: true,
                  })
                }
              />
            </>
          )}
          <Box mt={8} width="100%">
            <Button
              component={Link}
              href={Routes.profile}
              variant="outlined"
              color="inherit"
              fullWidth
              onClick={onClose}
            >
              <FormattedMessage id="rewards.notification.badge.view_all.button" />
            </Button>
          </Box>
        </DialogContentStyled>
      )}
    </Dialog>
  );
};

export default BadgeEarnedModal;
