import { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';

const { publicRuntimeConfig: config } = getConfig();

const success = (connection: AxiosRequestConfig) => {
  const mockUrls: RegExp[] = [];

  const isMockingEnabled = Boolean(config.MOCK_API_BASE && mockUrls.length);
  if (isMockingEnabled) {
    for (let i = 0; i < config.MOCK_URLS.length; i++) {
      if (connection.url && config.MOCK_URLS[i].test(connection.url)) {
        console.info('[HTTP]: now mocking url', connection.url);
        connection.baseURL = config.MOCK_API_BASE;
        break;
      }
    }
  }

  return connection;
};

const error = (connection: AxiosRequestConfig) => Promise.reject(connection);

export default { success, error };
