import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export const typography: TypographyOptions = {
  body1: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.2,
  },

  body2: {
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.2,
  },

  subtitle1: {
    fontSize: 16,
    lineHeight: 1.75,
    letterSpacing: 0.2,
    fontWeight: 'bold',
  },

  subtitle2: {
    fontSize: 14,
    lineHeight: 1.3,
    letterSpacing: 0.2,
  },

  caption: {
    fontSize: 12,
    lineHeight: 1.66,
    letterSpacing: 0.2,
  },

  overline: {
    fontSize: 12,
    lineHeight: 2.66,
    letterSpacing: 1,
  },

  button: {
    fontSize: 14,
    lineHeight: 1.71,
    letterSpacing: 0.4,
  },

  h1: {
    fontSize: 96,
    lineHeight: 1.17,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },

  h2: {
    fontSize: 60,
    lineHeight: 1.2,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },

  h3: {
    fontSize: 48,
    lineHeight: 1.17,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },

  h4: {
    fontSize: 34,
    lineHeight: 1.24,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },

  h5: {
    fontSize: 24,
    lineHeight: 1.15,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },

  h6: {
    fontSize: 18,
    lineHeight: 1.6,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
};
