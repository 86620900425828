import { DialogContent } from '@material-ui/core';
import styled from 'styled-components';

import BadgeChip from '../BadgeChip';

export const DialogContentStyled = styled(DialogContent)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const ImageWrap = styled.div`
  filter: drop-shadow(0px 18px 42px rgba(0, 0, 0, 0.5));
  overflow: visible;
  display: flex;
  justify-content: center;
`;

export const BadgeChipStyled = styled(BadgeChip)`
  box-shadow: 0px 0px 15px #ba7300;
`;

export const TextWrap = styled.div`
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14),
    0px 1px 14px rgba(0, 0, 0, 0.12);
  width: 100%;
`;
