import { AxiosInstance } from 'axios';

import {
  Collection,
  CollectionInstance,
  FeaturedGame,
  GameTeaser,
} from '../definitions/collections.definitions';

/**
 * Get a list of collections.
 * @param api An Axios instance
 * @returns A list of collections
 */
export const getCollections = (api: AxiosInstance) => api.get<CollectionInstance[]>('/collections');

/**
 * Get a list of games and services that the current user has recently played.
 * @param api An Axios instance
 * @returns A list of game teasers
 */
export const getJumpBackIn = (api: AxiosInstance) =>
  api.get<GameTeaser[]>('/collections/dynamic/jump-back-in', { authEnabled: true });

/**
 * Get a list of games and services that are new.
 * @param api An Axios instance
 * @returns A list of game teasers
 */
export const getNew = (api: AxiosInstance, limit?: number) =>
  api.get<GameTeaser[]>('/collections/dynamic/new-games', { params: { limit } });

/**
 * Get a list of games and services that are currently trending on the platform.
 * @param api An Axios instance
 * @returns A list of large game teasers
 */
export const getTrendingNow = (api: AxiosInstance) =>
  api.get<GameTeaser[]>('/collections/dynamic/trending-now');

/**
 * Get a list of games and services to win real prizes.
 * @param api An Axios instance
 * @returns A list of large game teasers
 */
export const getWinRealPrizes = (api: AxiosInstance, limit?: number) =>
  api.get<GameTeaser[]>('/collections/dynamic/win-real-prizes', { params: { limit } });

/**
   * Get a single collection of games using the id.
   * As part of the response, the backend will return a property called load_more_url. The frontend can follow this URL to load more items. If no more items are avaiable on the server, this URL will be empty.
For now, we always return 10 items.
   * @param api An Axios instance
   * @param id ID of the collection
   * @returns A Collection
   */
export const getCollectionById = (api: AxiosInstance, id: string, cursor?: string) =>
  api.get<Collection>(`/collections/${id}`, { params: { cursor } });

export const getFeaturedGames = (api: AxiosInstance) =>
  api.get<FeaturedGame[]>('/collections/dynamic/featured');

export const geRecommended = (api: AxiosInstance) =>
  api.get<GameTeaser[]>('/collections/dynamic/recommended');
