import { useCallback, useContext } from 'react';

import { useAnalytics } from 'hooks';

import { Events } from 'constants/events';
import { BadgeNotification } from 'services/api/definitions/rewards.definitions';
import { BadgeNotificationContext } from 'services/notifications/BadgeNotificationManager';

export const useBadgeNotification = () => {
  const { send } = useAnalytics();
  const manager = useContext(BadgeNotificationContext);

  const showBadgeNotification = useCallback(
    (notification: BadgeNotification) => {
      if (manager?.current) {
        send({
          event: Events.earnBadge,
          data: { badge: { name: notification.badgeName } },
          withUser: true,
        });
        manager.current.addNotification(notification);
      }
    },
    [manager, send],
  );

  return { showBadgeNotification };
};
