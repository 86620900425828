import getConfig from 'next/config';
import { TagManagerArgs } from 'react-gtm-module';

const { publicRuntimeConfig: config } = getConfig();

export const gtmParams: TagManagerArgs = {
  gtmId: config.TAG_MANAGER_ID,
  auth: config.TAG_MANAGER_AUTH,
  preview: config.TAG_MANAGER_PREVIEW,
};
