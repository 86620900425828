import React, { useEffect } from 'react';

import { useAnalytics, useAuth } from 'hooks';
import getConfig from 'next/config';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { useIntl } from 'react-intl';

const { publicRuntimeConfig: config } = getConfig();

const Head: React.VFC = () => {
  const intl = useIntl();
  const { locales } = useRouter();
  const { user } = useAuth();
  const { send } = useAnalytics();

  useEffect(() => {
    if (user) {
      send({ once: true });
    }
  }, [user, send]);

  return (
    <>
      <DefaultSeo
        title={intl.formatMessage({ id: 'seo.title.default' })}
        titleTemplate={`%s | ${intl.formatMessage({ id: 'seo.title_template' })}`}
        description={intl.formatMessage({ id: 'seo.description.default' })}
        openGraph={{
          title: intl.formatMessage({ id: 'seo.og.title' }),
          description: intl.formatMessage({ id: 'seo.og.description' }),
          site_name: intl.formatMessage({ id: 'seo.og.title' }),
          url: config.CLIENT_URL,
          type: 'website',
          images: [
            {
              url: `${config.CLIENT_URL}/favicon/favicon-512x512.png`,
              width: 512,
              height: 512,
            },
          ],
        }}
        twitter={{ cardType: 'summary' }}
        // dangerouslySetAllPagesToNoFollow
        // dangerouslySetAllPagesToNoIndex
        //TODO: Enable code once production should be indexable by Google + expand OG with proper images
        dangerouslySetAllPagesToNoFollow={config.ENV !== 'production'}
        dangerouslySetAllPagesToNoIndex={config.ENV !== 'production'}
      />
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {typeof window !== 'undefined' &&
          locales?.map(locale => (
            <link
              key={locale}
              rel="alternate"
              hrefLang={locale}
              href={`${window.location.origin}/${locale}/`}
            />
          ))}
        {typeof window !== 'undefined' && (
          <link rel="alternate" hrefLang="x-default" href={window.location.origin} />
        )}
      </NextHead>
    </>
  );
};

export default Head;
